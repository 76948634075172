<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[朋友圈兼职]5元/次，发朋友圈即可</p>
    <div>
      <div class="title">【内容详情】</div>
      <p class="psd">按要求发布转发朋友圈5元/次，安全绿色，正规文案</p>
    </div>
    <div>
      <div class="title">【联系方式】</div>
      <img src="@/assets/images/pyqwa.jpg" alt="" class="pic" />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
